import React from 'react';
import { Flex, Text } from '@chakra-ui/core';
import { Link } from 'gatsby';

import MenuItems from '../MenuItems';
import Avatar from '../Avatar';

const Sidebar = props => {
  return (
    <Flex
      display={{ base: 'none', lg: 'flex' }}
      as="nav"
      align="center"
      flexDirection="column"
      padding="1.5rem"
      wrap="wrap"
      bg="#F4F4F4"
      color="black"
      width="30%"
      height="100vh"
      overflow="hidden"
      {...props}
    >
      <Flex flexShrink="1" align="center" flexDirection="column">
        <Link to="/">
          <Avatar />
        </Link>
        <Text fontWeight="600" fontSize="lg">
          Stephen Bidwell
        </Text>
      </Flex>

      <MenuItems />
    </Flex>
  );
};

export default Sidebar;
