import React from 'react';
import { Flex } from '@chakra-ui/core';

import GretschLogo from '../GretschLogo';

const Footer = () => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      fontSize="0.75rem"
      marginTop="10px"
      marginBottom="10px"
      height="30px;"
      display={{ base: 'none', medium: 'flex' }}
      position="absolute"
      bottom={0}
      left={0}
      right={0}
    >
      &copy; 2020 Stephen Bidwell. A&nbsp;
      <a href="https://www.gretschdrums.com/">
        <GretschLogo width={40} />
      </a>
      &nbsp; artist.
    </Flex>
  );
};

export default Footer;
