import React from 'react';
import { Link } from 'gatsby';
import { Box } from '@chakra-ui/core';

const MenuItem = ({ children, to }) => (
  <Box mr={{ base: 4, lg: 0 }}>
    <Link to={to}>{children}</Link>
  </Box>
);

const MenuItems = ({ show }) => (
  <Box
    display={{ base: show ? 'flex' : 'none', md: 'flex' }}
    flexDirection={{ base: 'column', md: 'row', lg: 'column' }}
    justifyContent={{ base: 'flex-end' }}
    alignItems={{ base: 'flex-end', lg: 'center' }}
    textAlign={{ base: 'right', lg: 'center' }}
    width={{ base: 'full', md: 'auto' }}
    mt={{ base: 0, lg: 6 }}
    fontSize="xl"
    fontWeight="600"
  >
    <MenuItem to="/about">About</MenuItem>
    <MenuItem to="/listen">Listen</MenuItem>
    <MenuItem to="/booking">Booking</MenuItem>
    <MenuItem to="/writing">Writing</MenuItem>
    <a href="mailto:stephen.bidwell@gmail.com">Contact</a>
  </Box>
);

export default MenuItems;

/*
<Box
        display={{ base: show ? 'flex' : 'none', md: 'flex' }}
        flexDirection={{ base: 'column', md: 'row' }}
        justifyContent={{ base: 'flex-end' }}
        alignItems="flex-end"
        textAlign="right"
        width={{ base: 'full', md: 'auto' }}
        mt={{ base: 0 }}
        fontSize="xl"
        fontWeight="600"
      >
        <MenuItem to="/about">About</MenuItem>
        <MenuItem to="/listen">Listen</MenuItem>
        <MenuItem to="/booking">Booking</MenuItem>
        <MenuItem to="/writing">Writing</MenuItem>
        <MenuItem to="/contact">Contact</MenuItem>
      </Box>
    </Flex>

*/
