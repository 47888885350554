import React from 'react';

const GretschLogo = ({ width }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 526.63 457.17"
      width={width}
    >
      <path d="M221.47,214.71s-10.71-27.38-51.18-28.57S86.55,215.9,83.38,254.79c0,0-.4,38.49,38.49,38.49,0,0,23.81,1.59,39.29-10.71l19-36.51h26.19l-96,211.11L77,448.83,139.71,327a72.59,72.59,0,0,1-28.95,4c-15.87-1.19-62.3-10.72-62.3-69.05S112,152,172.27,153.6c0,0,40.47.4,63.49,31l-14.29,30.16Z" />
      <path d="M209.57,315.5l-18.65-.79,32.94-67.86h24.6s14.28,3.58,10.32,21.83-21.43,19.84-21.43,19.84l9.12,21-18.25,7.15-9.52-20.64-9.13,19.44Zm30.16-48c1.58-5.16-3.57-6.35-3.57-6.35l-4.77,9.92c3.18,1.59,6.75,1.59,8.34-3.57Z" />
      <polygon points="256.79 317.09 249.25 304 275.44 246.85 313.14 248.04 306 265.5 285.36 265.5 281.41 273.42 301.63 273.44 293.3 291.3 275.03 290.9 271.47 298.03 290.52 298.04 282.19 316.3 256.79 317.09 256.79 317.09" />
      <polygon points="290.14 317.09 327.82 234.55 281 233.76 288.54 216.3 470.28 217.89 462.74 235.75 349.25 234.16 309.57 317.09 290.14 317.09 290.14 317.09" />
      <path d="M325,306.77,333,289.32s8.34,8.73,16.27,9.12,6-7.94,2.78-11.11-13.49-13.49-8.73-23.41S355.6,248,365.52,248,387,258,387,258l-7.14,15.87s-11.51-11.11-16.67-5.56,1.19,8.34,2.78,10.72,14.29,13.89,7.14,25.4-13.49,13.48-23.81,13.09S325,306.77,325,306.77Z" />
      <path d="M432.19,273.44s-5.95-5.55-13.89-5.55-16.67,8.33-17.46,19,4.37,11.51,7.54,12.3,9.52-2.77,9.52-2.77l6,14.68s-6,5.16-17.85,4.76-23.41-7.54-23.81-25.79,20.63-42.06,37.3-40.48,21,9.13,21,9.13l-8.33,14.68Z" />
      <polygon points="462.35 247.25 481.79 247.25 471.08 273.42 482.98 272.65 508.38 217.09 526.63 217.49 482.21 316.3 463.54 316.3 473.46 292.89 461.15 293.28 449.65 316.69 430.6 317.07 462.35 247.25 462.35 247.25" />
      <path d="M370,333.4a8.52,8.52,0,1,1,8.54,8.43A8.4,8.4,0,0,1,370,333.4Zm8.54,7a7,7,0,1,0-6.85-7,6.78,6.78,0,0,0,6.85,7Z" />
      <path d="M376.73,338.27h-1.46v-9.7H379c2.29,0,3.42.85,3.42,2.76a2.52,2.52,0,0,1-2.51,2.67l2.76,4.27H381l-2.56-4.21h-1.69v4.21Zm1.76-5.45c1.25,0,2.36-.09,2.36-1.58,0-1.2-1.09-1.43-2.12-1.43h-2v3Z" />
      <path d="M306.41,395.41,260,355.5a144.55,144.55,0,0,0,17.3-24.21l-11.37-6.46c-23.11,40.65-66.06,65.89-112.1,65.89l-7.75,1L140.52,404l13.31-.19a141.71,141.71,0,0,0,98-39.82l54.54,31.43Z" />
      <path d="M24.35,258.77c0-72.76,58.08-132,129.48-132,53.29,0,101.9,34.07,120.95,84.78L287,207a144,144,0,0,0-31.25-49.41l0-2.59a3.39,3.39,0,0,0,1-.17l4.52,4.6a1.64,1.64,0,1,0,2.34-2.31l-4.56-4.64a4.13,4.13,0,0,0-5-5.07L249,142.2a1.64,1.64,0,1,0-2.33,2.3l5.07,5.15a3.73,3.73,0,0,0-.18.93l-3,0a143,143,0,0,0-66.57-33.9v-8.41l40.95.73-.24-16.09-41-1.22-.24-4.38s-.48-2-2.19-2.2-1.95,1.71-1.95,1.71l-1.18,28.77a137.68,137.68,0,0,0-20.26-1.78l.23-87.94s84.59,2.93,84.35,0-5.12-3.17-6.58-3.41-9.27-.73-9.27-.73a193.68,193.68,0,0,1-54.12-10.24C165.6,10,155.61,8,155.61,8V6.82a15.9,15.9,0,0,0,3.9-.48c1.46-.49,2.2-.73,1.71-3.42A2.91,2.91,0,0,0,158.53.24s-4.14.49-7.31.49-4.87,0-6.58-.73h-2a3.35,3.35,0,0,0-1.41,2.19,3.12,3.12,0,0,0,.49,2.93l6.58,1v2.2a51.88,51.88,0,0,0-7.8,1.71c-2.68,1-25.35,6.58-34.86,7.79s-33.89,2.2-39,2.44-6.09,4.39-6.09,4.39l87,1.46.46,87.81A138.07,138.07,0,0,0,129,116l-.73-25.53a2.45,2.45,0,0,0-2.43-2.2c-2.2,0-1.47,2.44-1.47,2.44l-.24,5.61s-6.34-1.71-13.41-3.17a126.07,126.07,0,0,0-15.6-2l-.25,20.49s.74.24,13.17-1.22,15.85-2.69,15.85-2.69V117a141.29,141.29,0,0,0-65.56,34.24l-6.8.25a4.32,4.32,0,0,0-.21-1l4.38-4.72a1.64,1.64,0,1,0-2.4-2.23l-4.43,4.77a4.13,4.13,0,0,0-4.85,5.21l-5,5.35a1.65,1.65,0,0,0,.09,2.32,1.63,1.63,0,0,0,2.32-.09l4.92-5.3a3.75,3.75,0,0,0,.94.14l.23,6.3a145.71,145.71,0,0,0-36.17,90.14l-5.26,5.26a3.69,3.69,0,0,0-.83-.59v-6.45a1.64,1.64,0,0,0-3.28,0v6.51a4.13,4.13,0,0,0,0,7.12v7.3a1.64,1.64,0,0,0,3.28,0v-7.23a4.53,4.53,0,0,0,.78-.54l5.55,5.56a145.74,145.74,0,0,0,35.11,85.38L.11,395.52,54.54,363A142.68,142.68,0,0,0,100,393.12L105,381C56,360.7,24.35,312.71,24.35,258.77Z" />
    </svg>
  );
};

export default GretschLogo;
