import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/core';
import { Link } from 'gatsby';

import Avatar from '../Avatar';
import MenuItems from '../MenuItems';

const Navbar = props => {
  const [show, setShow] = React.useState(false);
  const handleToggle = () => setShow(!show);

  return (
    <Flex
      display={{ base: 'flex', lg: 'none' }}
      as="nav"
      align="center"
      justifyContent={{ base: 'space-between' }}
      flexDirection={{ base: 'row' }}
      padding="1.5rem"
      wrap="wrap"
      bg="#F4F4F4"
      color="black"
      width={{ base: '100%' }}
      {...props}
    >
      <Flex
        flexShrink="1"
        align="center"
        mr={5}
        flexDirection={{ base: 'row' }}
      >
        <Link to="/">
          <Avatar />
        </Link>
        <Text fontWeight="600" fontSize="lg" margin={{ base: '0 0 0 10px' }}>
          Stephen Bidwell
        </Text>
      </Flex>

      <Box display={{ base: 'block', md: 'none' }} onClick={handleToggle}>
        <svg
          fill="black"
          width="12px"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Menu</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
        </svg>
      </Box>

      <MenuItems show={show}/>
    </Flex>
  );
};

export default Navbar;
