import React from 'react';
import { Flex, Box } from '@chakra-ui/core';

import Navbar from './Navigation/Navbar';
import Sidebar from './Navigation/Sidebar';
import Footer from './Footer';

const Layout = ({ children }) => {
  return (
    <>
      <Flex
        flexDirection={{ base: 'column', lg: 'row' }}
        height="100vh"
        overflow="hidden"
      >
        <Navbar />
        <Sidebar />
        <Box
          width="100%"
          height={{ base: 'auto', large: '100vh' }}
          margin="0 auto"
          overflow="auto"
        >
          <Box
            position="relative"
            padding="40px 20px 60px 20px"
            minHeight={{ base: 'calc(100vh - 150px)', lg: '100vh' }}
          >
            <main>{children}</main>
            <Footer />
          </Box>
        </Box>
      </Flex>
    </>
  );
};

export default Layout;
